import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { isLoaded } from "./actionUtils";

export type User = types.User.User;

export type UserUpdate = Partial<{
  email: string;
  password: string;
  oldPassword: string;
  notificationNumber: string;
  notificationNumberCode: string; // validation code when user changes notification number
  name: string;
  firstName: string;
  lastName: string;
  role: string;
  status: string;
  language: string;
  gravatarUrl: string;
  hmac: string;
  isApprover: boolean;
}>;

export function loadUser(force?: boolean) {
  const { user } = store.getState();

  if (force || !isLoaded(user.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "user");

    request
      .POST<{ user: types.User.User; features: types.Merchant.FeatureSettings }>("/v1/user/current")
      .then(({ body }) => {
        if ("user" in body) {
          standardDispatch(OpCode.DATA, "user", {
            data: body.user,
          });
        }
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "user", {
          errors,
        });
      });
  }
}

export async function updateUser(changes: UserUpdate) {
  const { body } = await request.POST<{ user: types.User.User }>("/v1/user/update", { query: changes });
  standardDispatch(OpCode.DATA, "user", {
    data: body.user,
  });
}
