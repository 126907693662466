import { Card, Container, Flag, Grid, Icon, LogoLoader, Text, Title } from "components";
import { ActivateAlert } from "features/onboarding";
import { gridProps } from "pages/SettingsPage/PayoutMethods";
import React from "react";
import { Helmet } from "react-helmet";
import { Features, MerchantSettings, useMerchantSettings } from "store/hooks/merchantSettings";
import { BaseStatus } from "store/reducers/standardReducer";
import { Avatar } from "antd";
import { CountryCode } from "@trolley/common-frontend";

export enum TAX_COMPLIANCE_PATHS {
  TAX = "/settings/tax-compliance",
  US_TAX = "/settings/tax-compliance/us",
  EU_TAX = "/settings/tax-compliance/marketplace-reporting",
  EU_TAX_WIZARD = "/wizard/tax/eu",
}

/**
 * canEnableTax returns whether we can enable the tax compliance.
 * There can only be 1 tax compliance enabled at a time
 */
export function canEnableTax(merchantSettings: MerchantSettings | undefined, features: Partial<Features>, tax: "US" | "EU"): boolean {
  switch (tax) {
    case "US":
      return !!features.tax && merchantSettings?.euTax?.enabled === false;
    case "EU":
      return !!features.euTax && merchantSettings?.tax?.enabled === false;
    default:
      return false;
  }
}

export default function TaxCompliance() {
  const { features, data: merchantSettings, status: merchantSettingsStatus } = useMerchantSettings();
  const usTax = merchantSettings?.tax;
  const euTax = merchantSettings?.euTax as any;
  const isUSEnabled = !!features.tax && !!usTax?.enabled;
  const isEUEnabled = !!features.euTax && !!(euTax as any)?.enabled;

  return (
    <Container>
      <Helmet>
        <title>Tax Compliance</title>
      </Helmet>
      <ActivateAlert />
      <Title>Tax Compliance</Title>

      <LogoLoader spinning={merchantSettingsStatus === BaseStatus.LOADING}>
        <Grid>
          <Grid.Item {...gridProps} key="us-tax">
            <Card
              url={TAX_COMPLIANCE_PATHS.US_TAX}
              enabled={isUSEnabled}
              title={<Text weight="bold">US Tax Reporting</Text>}
              icon={<Icon theme="solid" type="circle-dollar" size="large" color={isUSEnabled ? "green" : "black"} />}
              styleBox={{ minWidth: 338 }}
              cornerElement={
                <Avatar.Group size="small" style={{ opacity: 0.5 }}>
                  <Avatar src={<Flag code={CountryCode.US} showLabel={false} />} />
                </Avatar.Group>
              }
            />
          </Grid.Item>

          <Grid.Item {...gridProps} key="eu-tax">
            <Card
              url={TAX_COMPLIANCE_PATHS.EU_TAX}
              enabled={isEUEnabled}
              title={<Text weight="bold">Marketplace Reporting (Non-US)</Text>}
              icon={<Icon theme="solid" type="shop" color={isEUEnabled ? "green" : "black"} />}
              styleBox={{ minWidth: 338 }}
              cornerElement={
                <Avatar.Group size="small" style={{ opacity: 0.5 }}>
                  <Avatar src={<Flag code="EU" showLabel={false} />} />
                  <Avatar src={<Flag code={CountryCode.GB} showLabel={false} />} />
                  <Avatar src={<Flag code={CountryCode.NZ} showLabel={false} />} />
                  <Avatar src={<Flag code={CountryCode.AU} showLabel={false} />} />
                  <Avatar src={<Flag code={CountryCode.CA} showLabel={false} />} />
                </Avatar.Group>
              }
            />
          </Grid.Item>
        </Grid>
      </LogoLoader>
    </Container>
  );
}
