import { getAlphaColor } from "antd/lib/theme/themes/default/colorAlgorithm";
import { Badge, BadgeProps, Box, Space, Status, Text, Tooltip, TooltipProps } from "components";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import styled, { classnames, createUseStyle } from "style/classname";

export interface CardProps {
  title: string | React.ReactNode;
  enabled: boolean;
  url?: string;
  badgeProps?: BadgeProps;
  icon: React.ReactNode;
  tooltipProps?: TooltipProps;
  fitContent?: boolean;
  styleBox?: React.CSSProperties;
  cornerElement?: React.ReactNode;
  corner?: "left" | "right";
  cornerStyle?: React.CSSProperties;
}

export default function Card({ title, enabled, url, icon, badgeProps, tooltipProps, fitContent, styleBox, cornerElement, corner, cornerStyle }: CardProps) {
  const { isActive } = useMerchantSettings();
  const history = useHistory();
  const styledBox = useStyledBox({ enabled: isActive && enabled && !!url, active: isActive && !!url, fitContent });

  let content = (
    <Box
      className={styledBox}
      onClick={
        url
          ? () => {
              history.push(url);
            }
          : undefined
      }
      style={styleBox}
    >
      <>
        {!!cornerElement && (
          <div
            style={{
              position: "relative",
              float: corner ?? "left",
              top: "-10px",
              ...(corner === "right" ? { right: "25px" } : { left: "-10px" }),
              ...(cornerStyle ?? {}),
            }}
          >
            <div style={{ position: "absolute" }}>{cornerElement}</div>
          </div>
        )}
        <Space direction="column" gap="middle">
          <div className="icon-bg">{icon}</div>
          <Text weight="bold">{title}</Text>
          <Text type={enabled ? "success" : "text"} className={classnames(["status"])}>
            <Status type={enabled ? "enabled" : "not enabled"} />
          </Text>
        </Space>
      </>
    </Box>
  );

  if (tooltipProps?.title) {
    content = <Tooltip {...tooltipProps}>{content}</Tooltip>;
  }

  if (badgeProps) {
    content = <Badge.Ribbon {...badgeProps}>{content}</Badge.Ribbon>;
  }

  return content;
}

function Empty({ message, fitContent }: { message: string | ReactNode; fitContent?: boolean }) {
  const styledBox = useStyledBox({ enabled: false, active: false, fitContent });

  return (
    <Box className={styledBox}>
      <Text size="large">{message}</Text>
    </Box>
  );
}
Card.Empty = Empty;

export const useStyledBox = createUseStyle<{ enabled: boolean; active: boolean; fitContent?: boolean }>(({ theme, ...props }) =>
  styled`
  &.box {
    position: relative;
    color: ${theme.colorText};
    margin: 0;
    padding-top: 8px;
    ${
      !!props.fitContent
        ? `
        min-height: 140px;
      `
        : `
        height: 140px;
      `
    }
    ${
      props.enabled
        ? `
      border-color: ${theme.colorSuccess};
      `
        : `
      background-color: ${getAlphaColor(theme.colorWhite, 0.6)};
      `
    }
    ${
      props.active
        ? `
        &:hover {
          .icon-bg {
            background-color: ${getAlphaColor(theme.colorWhite, 0.6)};
          }
        }
        `
        : `
        background-color: ${theme.colorBgContainerDisabled};
        border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};
        opacity: 0.6;
      `
    }
    .icon-bg {
      height: 40px;
      transition: background-color 0.2s ease;
      width: 40px;
      border-radius: 40px;
      background-color: ${props.enabled ? theme.colorSuccessBg : theme.grey1};
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    ${
      !!props.fitContent
        ? `
          & .box-content {
            padding-left: 16px;
            padding-right: 16px;
          }
      `
        : ``
    }
  }
`(),
);
