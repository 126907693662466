import * as types from "@trolley/common-frontend";
import { RecursivePartial, SupportedTaxRegions } from "@trolley/common-frontend";
import { batch as reduxBatch } from "react-redux";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { isLoaded } from "./actionUtils";

export interface MerchantSettings extends types.Merchant.Merchant {}

export interface Features extends types.Merchant.FeatureSettings {}

export type MerchantSettingsUpdate = {
  name?: string;
  phone?: string;
  website?: string;
  tax?: Partial<types.Merchant.USTaxSettings>;
  euTax?: Partial<types.Merchant.EUTaxSettings>;
  supportedTaxRegions?: Partial<Record<SupportedTaxRegions, boolean>>;
  payment?: RecursivePartial<MerchantSettings["payment"]>;
  allowedDomains?: string;
  allowedIPs?: string;
  security?: {
    requireTFA: boolean;
  };
};

export type MerchantTrustSettingsUpdate = Partial<types.Merchant.TrustSettings>;

export async function asyncLoadMerchantSettings() {
  standardDispatch(OpCode.LOADING, "merchantSettings");
  try {
    const { body } = await request.POST<{ merchant: MerchantSettings; features: Features }>("/v1/merchant/current");
    const merchant: MerchantSettings = body.merchant;
    reduxBatch(() => {
      standardDispatch(OpCode.DATA, "features", {
        data: body.features,
      });
      standardDispatch(OpCode.DATA, "merchantSettings", {
        data: merchant,
      });
    });

    return { merchantSettings: merchant, features: body.features };
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "merchantSettings", {
      errors,
    });

    throw errors;
  }
}

export function loadMerchantSettings(force?: boolean) {
  const data = store.getState().merchantSettings;

  if (force || !isLoaded(data.fetchStatus.data)) {
    standardDispatch(OpCode.LOADING, "merchantSettings");

    request
      .POST<{ merchant: MerchantSettings; features: Features }>("/v1/merchant/current")
      .then((res) => {
        reduxBatch(() => {
          standardDispatch(OpCode.DATA, "features", {
            data: res.body.features,
          });
          standardDispatch(OpCode.DATA, "merchantSettings", {
            data: res.body.merchant,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "merchantSettings", {
          errors,
        });
      });
  }
}

export async function updateMerchantTrustSettings(change: MerchantTrustSettingsUpdate) {
  standardDispatch(OpCode.LOADING, "merchantSettings");
  try {
    const { body } = await request.POST<{ trust: MerchantSettings["trust"] }>("/v1/trust/update", { query: change });

    standardDispatch(OpCode.UPDATE, "merchantSettings", {
      update: { trust: body.trust },
    });

    return body;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "merchantSettings", {
      errors,
    });
    throw errors;
  }
}

export async function updateMerchantSettings(change: MerchantSettingsUpdate) {
  standardDispatch(OpCode.LOADING, "merchantSettings");

  try {
    const { body } = await request.POST<{ merchant: MerchantSettings; features: Features }>("/v1/merchant/update", { query: change });
    reduxBatch(() => {
      standardDispatch(OpCode.DATA, "merchantSettings", {
        data: body.merchant,
      });
      if (change.tax?.paymentBehaviour) {
        standardDispatch(OpCode.RESET, "recipients"); // because it changes the status of recipients
      }
    });

    return body.merchant;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "merchantSettings", {
      errors,
    });
    throw errors;
  }
}

export function resolveMerchantEntitlements() {
  standardDispatch(OpCode.LOADING, "merchantSettings");

  request
    .POST<{ merchant: MerchantSettings; features: Features }>("/v1/merchant/entitlements/resolve")
    .then((res) => {
      reduxBatch(() => {
        standardDispatch(OpCode.DATA, "features", {
          data: res.body.features,
        });
        standardDispatch(OpCode.DATA, "merchantSettings", {
          data: res.body.merchant,
        });
      });
    })
    .catch((errors) => {
      standardDispatch(OpCode.ERROR, "merchantSettings", {
        errors,
      });
    });
}
